import { platformGradients } from '@diwala/killer-shrew';
import styled from '@emotion/styled';

export const FullScreenEmployer = styled.div`
  position:fixed;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100%;
  height: 100%;
  ${platformGradients['employer']}
`
