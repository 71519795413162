import React from 'react'
import { CenterView } from 'layouts/unauthed';
import { Card } from 'components/card';
import { Text, PrimaryButton } from '@diwala/killer-shrew';

import nativeStyled from '@emotion/native';
import styled from '@emotion/styled';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 44px;
`


const ButtonHolder = styled.div`
  position:absolute;
  width: 100%;
  bottom: 0;
  padding-bottom: 30px;
`

const Button = nativeStyled(PrimaryButton)`
  align-self: center;
`



export default () => {
  return (
    <CenterView>
      <Card>
        <Content>
          <Text heading={2}>Welcome to Diwala!</Text>
          <Text style={{paddingTop: '16px', paddingLeft: '27px'}} heading={4}>Verify your job candidate, instantly</Text>
          <Text style={{marginTop: '118px'}} heading={3}>First 5 verification are free</Text>
          <Text style={{marginTop: '36px'}} heading={6}>You need to create an account or log in</Text>
        </Content>
        <ButtonHolder>
          <Button title={'Sign up'} />
          <Button style={{marginTop: '24px' }} title={'Login'} inverted={true} />
        </ButtonHolder>
      </Card>
    </CenterView>
  )
}
