import Force from '@diwala/force';

const force = new Force({storageBackend: localStorage});

export const checkToken = () => {
  console.log(force)
}

export const useAuth = () => {
  return [false]
}
