// Import here Polyfills if needed. Recommended core-js (npm i -D core-js)
// import "core-js/fn/array.find"
// ...

import fetch from "isomorphic-fetch";
import jwtDecode from "jwt-decode";

interface IForceConstructor {
  storageBackend?: Storage
  host?: string
}

interface RequestData extends RequestInit {
  data?: any
}

interface IAuthCodeRequestPhone {
  phone: string
  platform: 'mobile' | 'desktop'
}

interface IAuthCodeRequestEmail {
  email: string
  platform: 'mobile' | 'desktop'
}

const PERSISTANCE_KEY = 'force-token'

interface IBaseResponseError {
  status: "error"
  message?: string
}

interface IBaseResponseSuccess {
  status: "success"
}

type BaseAsyncResponse = IBaseResponseError | IBaseResponseSuccess

export default class Force {

  private storage?: Storage
  private host: string

  private _token: string | undefined = undefined

  get tokenInfo(){
    return this._token ? jwtDecode<any>(this._token) : undefined
  }

  private get token(){
    return this._token
  }

  private set token(value: string | undefined){
    if (value) {
      if (this.storage){
        this.storage.setItem(PERSISTANCE_KEY, value)
      }
      this._token = value
    } else {
      if (this.storage){
        this.storage.removeItem(PERSISTANCE_KEY)
      }
      this._token = undefined
    }
  }

  constructor({storageBackend, host}: IForceConstructor){
    this.storage = storageBackend;
    if (storageBackend === undefined){
      console.warn('Storage backend is not provided. Force token will not be persisted.')
    }
    this.host = host || 'http://localhost:5000'
  }

  private _headers = new Headers([['Content-Type', 'application/json']])

  private get authHeaders(){
    if (this.token && !this._headers.has('Authorization'))
      this._headers.append('Authorization', `Bearer ${this.token}`)
    return this._headers
  }

  /**
   * To be used later
   */
  private get isTokenExpired() {
    if (this.token === undefined) return false;
    const expiretime = jwtDecode<{exp: number}>(this.token).exp;
    const now = Math.floor(new Date().getTime() / 1000);
    return now > expiretime
  }

  /**
  * Patched fetch to contain body encoding logic and all logic regarding the requests
  */
  private request(url: RequestInfo, { data, ...cleanOptions }: RequestData = {}){
    return fetch(url, {
      ...cleanOptions,
      body: data ? JSON.stringify(data) : undefined,
      headers: this.authHeaders,
    }).then(x=> x.json())
  }

  /**
   * Gets basic credentials for user to access Diwala API
   * @param credentials Map containing either password or email
   */
  async connect({ platform , ...credentials}: IAuthCodeRequestPhone | IAuthCodeRequestEmail): Promise<BaseAsyncResponse>{
    try {
      const { token } = await this.request(`${this.host}/api/user/auth/nondid?platform=${platform}`, {
        data: credentials,
        method: 'POST'
      })
      this.token = token
      return {
        status: 'success'
      }
    } catch (error) {
      return {
        status: 'error'
      }
    }
  }

  /**
   * Verifies code and updates the token accordingly
   * @param code Verification code provided by the user
   */
  async verifyAuthCode(code: string): Promise<BaseAsyncResponse>{
    try {
      const { data: { token } } = await this.request(`${this.host}/api/user/auth/nondid/code/${code}`)
      this.token = token
      return {
        status: 'success'
      }
    } catch (error) {
      return {
        status: 'error'
      }
    }
  }

  /**
   * Sets user password and updates token
   */
  async setPassword(password: string){
    try {
      const { data: { token } } = await this.request(`${this.host}/api/user/secure/generate`, {
        method: 'POST',
        data: { password }
      })
      this.token = token
      return {
        status: 'success'
      }
    } catch (error) {
      return {
        status: 'error'
      }
    }
  }

  /**
   * Request or rerequest API to send auth code
   */
  async requestAuthCode(password: string){
    try {
      await this.request(`${this.host}/api/user/auth/request/code`)
      return {
        status: 'success'
      }
    } catch (error) {
      return {
        status: 'error'
      }
    }
  }
}
