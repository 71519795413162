import React from 'react'
import styled from '@emotion/styled';

export const CenterView = ({children}: React.PropsWithChildren<any>) => {
  const Layout = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <Layout>
      {children}
    </Layout>
  )
}
