import React from 'react';
import styled from '@emotion/styled';
import { SmallBrandLogo } from '@diwala/killer-shrew';

export const WrappedBrandLogo = () => {
  const WrappedBrandLogo = styled(SmallBrandLogo)`
    margin-left: 31px;
    margin-top: 31px;
    width: 100px;
    position: absolute;
  `
  return (
    <WrappedBrandLogo color={'white'} />
  )
}
