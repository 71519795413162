import React from 'react'
import styled from '@emotion/styled';

export const Card = ({children}: React.PropsWithChildren<any>) => {
  const StyledCard = styled.div`
  background: rgba(255, 255, 255, 0.96);
  border: 1px solid rgba(46, 91, 255, 0.16);
  box-sizing: border-box;
  position: relative;
  /* Card on Token */

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  min-width: 360px;
  max-width: 360px;
  min-height: 560px;
  max-height: 560px;
`

  return (
    <StyledCard>
      {children}
    </StyledCard>
  )
}

