import React, { useState } from 'react';
import { NavigationBar, NavItem } from '@diwala/killer-shrew';
import { DiwalaIcon as Icon } from '@diwala/killer-shrew';

import { useAuth } from 'services/auth';
import { FullScreenEmployer } from 'layouts/atoms';
import { WrappedBrandLogo, } from 'components/logos';



export default ({ children }: React.PropsWithChildren<any>) => {
  const [open, setOpen] = useState(false);
  const [isActive, setActive] = useState(true);
  const [isAuthed] = useAuth();

  console.log(setActive)
  console.log(isAuthed)

  const logo = {
    onClick: () => {
      console.log('click logo')
    },
  };

  const getRoutes = () => {

    const item = {
      label: 'World',
      onClick: ()=>console.log('hei hei'),
      icon: <Icon name="courses" color="#fff" size={20} />,
    }

    const topShelf = [
      <NavItem label={item.label} onClick={item.onClick} icon={item.icon} isActive={isActive}/>,
      <NavItem label={item.label} onClick={item.onClick} icon={item.icon} isActive={isActive}/>,
    ]

    const bottomShelf = [
      <NavItem label={item.label} onClick={item.onClick} icon={item.icon} isActive={isActive}/>,
      <NavItem label={item.label} onClick={item.onClick} icon={item.icon} isActive={isActive}/>,
    ]
    return {
      topShelf,
      bottomShelf
    }
  }

  if(isAuthed) {
    return (
      <NavigationBar type={'employer'} logo={logo} routes={getRoutes()} open={open} setOpen={setOpen}>
        {children}
      </NavigationBar>
    );
  } else {


    return (
      <FullScreenEmployer>
        <WrappedBrandLogo />
        {children}
      </FullScreenEmployer>
    )
  }
}
