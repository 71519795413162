import React, { Suspense } from 'react'
import { Fonts } from '@diwala/killer-shrew';

import MainLayout from 'layouts/main-layout';
import Welcome from 'views/welcome';


const App = () => {

  return (
    <>
      <Fonts />
      <MainLayout>
        <Suspense fallback={<div>loading..</div>}>
          <Welcome />
        </Suspense>
      </MainLayout>
    </>
  )
}

export default App;
